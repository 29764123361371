<template>
  <div class="form-div">
     <div class="title">新冠疫苗</div>
     <Form class="isForm" label-position="right" :label-width="150">
        <FormItem class="isNoWidth">
              <span slot="label">疫苗接种情况:</span>
                <RadioGroup v-model="archiveVaccinationRo.finishStatus">
                    <Radio v-for="(item,index) in finishStatusType" :key="index" :label="item.dictKey">
                        {{item.dictValue}}
                    </Radio>          
                </RadioGroup>
        </FormItem>
        <FormItem class="isNoWidth" v-if="archiveVaccinationRo.finishStatus == 5">
              <span slot="label">未接种原因:</span>
              <Select clearable v-model="archiveVaccinationRo.unfinished" >
                <Option
                v-for="item in unfinishedType"
                :value="item.dictKey"
                :key="item.dictKey"
                >{{ item.dictValue }}</Option>
              </Select>
        </FormItem>
        <div class="isNoWidth widthNone" v-for="(item,index) in vaccinationDetailRoList" :key="index">
            <div class="item" >
                <span slot="label">接种第{{index+1}}针时间</span>
                <DatePicker placeholder="请选择接种时间" v-model="item.vaccinationDate" />
            </div>
            <div class="item">
                <span slot="label">疫苗品牌</span>
                <Select clearable v-model="item.brand" >
                <Option
                v-for="item in brandType"
                :value="item.dictKey"
                :key="item.dictKey"
                >{{ item.dictValue }}</Option>
              </Select>
            </div>
        </div>
     </Form>
     <div class="title">核酸检测情况</div>
     <Form class="isForm" label-position="right" :label-width="150">
         <div class="isNoWidth widthNone" v-for="(item,index) in archiveNatRoList" :key="index">
            <div class="item" >
                <span slot="label">检测时间</span>
                <DatePicker placeholder="请选择检测时间" v-model="item.natDate" />
            </div>
            <div class="item">
                <span slot="label">检测结果</span>
                <Select clearable v-model="item.natResult" >
                <Option
                v-for="item in natResultType"
                :value="item.dictKey"
                :key="item.dictKey"
                >{{ item.dictValue }}</Option>
              </Select>
               <Icon color="#2d8cf0" style="margin-left:20px;" type="md-add-circle" @click="addIcon" size="30" v-if="index == 0" />
               <Icon color="#ed4014" style="margin-left:20px;" type="md-close-circle" @click="increamIcon(index)" size="30" v-else/>
            </div>
        </div>
     </Form>
  </div>
</template>

<script>
export default {
  props:{
    archiveVaccinationRo:{
        type:Object,
        default:()=>{
            return {}
        }
    },
    // 新冠疫苗
    vaccinationDetailRoList:{
        type:Array,
        default:()=>{
             return [{
              vaccinationDate:'',
              brand:'',
              batch:'0'
          },
          {
              vaccinationDate:'',
              brand:'',
              batch:'1'
          },
          {
              vaccinationDate:'',
              brand:'',
              batch:'2'
          }]
        }
    },
    // 核酸检测情况
    archiveNatRoList:{
        type:Array,
        default:()=>{
            return [{natDate:'',natResult:''}]
        }
    },
    userId:{
            type:Object,
            default:()=>{
                return ''
            }
        },
  },
  data(){
    return{
      isCheck:false,
      finishStatusType:[
        // {dictKey:'0',dictValue:'已完成全程接种'},
        // {dictKey:'1',dictValue:'已接种第一针'},
        // {dictKey:'2',dictValue:'已接种第二针'},
        // {dictKey:'9',dictValue:'未接种'},
      ],
      natResultType:[
          {dictKey:'1',dictValue:'阴性'},
          {dictKey:'2',dictValue:'阳性'},
      ],
      unfinishedType:[],
 
      brandType:[],
    }
  },
  created(){
      this.getDicType('NOT_VACCINATED_REASON','unfinishedType')
      this.getDicType('VACCINE_BRAND','brandType')
      this.getDicType('VACCINATION_STATUS','finishStatusType')
      this.getDetail()
  },
  methods:{ 
    //   删除一个的安妮
    increamIcon(index){
        this.archiveNatRoList.splice(index,1)
    },
    //   增加一个的按钮
    addIcon(){
        this.archiveNatRoList.push({ natDate:'',natResult:''})
    },
    changeCheck(val){
      this.isCheck = val
      this.$emit('changeCheck',val)
      // console.log(val);
    },
    // 获取字典接口的公共方法
        getDicType(type,model){
            this.$get('/voteapi/api/common/sys/findDictByDictType',{
                dictType:type
            }).then(res=>{
                this[model] = res.dataList
            })
        },
         getDetail(){
          if(this.userId && this.userId != ''){
            this.$get('/syaa/api/syuser/pc/residentArchive/selectArchiveVaccination',{
              userId:this.userId,
              // familyId:this.familyId
            }).then(res=>{
              if(res.code == 200){
                  this.$emit('getVaccination',res.data)
              }else{
                this.$Message.error({
                  content:'疫情防控档案信息获取失败',
                  background:true
                })
                return
              }
            })
          }
          
        }
  },
   watch:{
     partyCheck:{
       hanlder(val,newVal){
         if(val){
           this.isCheck = val
         }
       }
     }
   }
}
</script>

<style lang="less" scoped>
.form-div{
    width:100%;
    user-select: none;
    .isForm{
        display: flex;
        flex-wrap: wrap;
        .isWidth{
        width: 40%;
        margin-right: 40px;
        }
        .isNoWidth{
            width: 100%;
        }
        .widthNone{
            display: flex;
            margin: 20px 0 20px  50px;
            .item{
                width: 45%;
                margin-right: 20px;
                display: flex;
                height: 30px;
                
                span{
                    text-align: right;
                    width: 102px;
                    line-height: 30px;
                    padding-right:10px;
                }
            }
        }
    }
    
}
/deep/.validate {
  &::before {
    content: "*";
    display: inline-block;
    margin-right: 4px;
    line-height: 1;
    font-family: SimSun;
    font-size: 14px;
    color: #ed4014;
  }
}
/deep/.ivu-date-picker{
    display: inline;
}
.title{
    font-size: 18px;
    font-weight: bold;
}
</style>